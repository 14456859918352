import { gql, useQuery } from '@apollo/client';

const MEASUREMENTS_QUERY = gql`
  query measurements($providerId: Int!) {
    measurements(provider_id: $providerId) {
      measurement_id
      optimization_target_type
      name
      provider_id
    }
  }
`;
type Options = {
  providerId: number;
};

function useMeasurementsQuery({ providerId }: Options): any {
  const query = useQuery<{ measurements: { measurement_id: number; name: string }[] }, { providerId: number }>(
    MEASUREMENTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        providerId,
      },
    },
  );
  return query;
}

export { MEASUREMENTS_QUERY, useMeasurementsQuery };
