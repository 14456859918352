import { gql, useQuery } from '@apollo/client';

const FUNNEL_STEP_UTM_MAPPING_QUERY = gql`
  query FunnelStepUtmMappingParams {
    funnelStepUtmMappingParams {
      name
      type
    }
  }
`;

function useFunnelStepUtmMapping() {
  return useQuery(FUNNEL_STEP_UTM_MAPPING_QUERY, { fetchPolicy: 'cache-first' });
}

export { useFunnelStepUtmMapping, FUNNEL_STEP_UTM_MAPPING_QUERY };
