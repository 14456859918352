import * as Styles from '../../styles/ContentTableRow';
import SvgChevronDown from '../../../../components/icons/ChevronDown';
import { BigHeaderCell } from '../../styles/OptimizationProposal';
import React from 'react';
import { NexoyaFunnelStepV2 } from '../../../../types';
import { capitalize } from 'lodash';
import { withSortSkipped } from '../OptimizationProposal/withSortSkipped';
import { sortTypes } from '../../../../components/Table/sortTypes';
import { ACTIONS_HEADER_ID } from '../OptimizationProposal/columns';

interface Props {
  funnelSteps: NexoyaFunnelStepV2[];
  hasBidStrategy: boolean;
  hasBudgetLimits: boolean;
  hasLabels: boolean;
}

export const getColumns = ({ funnelSteps, hasLabels, hasBidStrategy, hasBudgetLimits }: Props) =>
  [
    {
      accessor: ACTIONS_HEADER_ID,
      disableSortBy: true,
      Header: '',
      tableManagerHeader: <BigHeaderCell>Content</BigHeaderCell>,
      className: 'border-right',
      columns: [
        {
          Header: '',
          accessor: 'editRow',
          id: 'editRow',
          width: '48',
          disableSortBy: true,
          disableHiding: true,
        },
        {
          width: 50,
          disableSortBy: true,
          disableHiding: true,
          isHiddenInManager: true,
          id: 'expander',
          Cell: ({ row }) =>
            row.canExpand ? (
              <Styles.ChevronWrap
                expanded={row.isExpanded}
                className="mx-auto h-full w-fit items-center !justify-center"
                {...row.getToggleRowExpandedProps()}
              >
                <SvgChevronDown />
              </Styles.ChevronWrap>
            ) : null,
        },
        {
          Header: <BigHeaderCell className="!py-4">Content</BigHeaderCell>,
          id: 'content',
          accessor: 'content',
          className: 'border-right',
          minWidth: 300,
          sortType: withSortSkipped(sortTypes.jsxKey),
          disableHiding: true,
          enableColumnResize: true,
        },
      ],
    },
    {
      accessor: 'optimizationHeader',
      disableSortBy: true,
      Header: '',
      tableManagerHeader: <BigHeaderCell>Optimization</BigHeaderCell>,
      columns: [
        {
          Header: <BigHeaderCell>Optimization</BigHeaderCell>,
          width: 200,
          accessor: 'optimization',
          className: 'border-right',
          enableColumnResize: true,
          sortType: withSortSkipped(sortTypes.jsxKey),
        },
      ],
    },
    {
      accessor: 'contentLevelHeader',
      disableSortBy: true,
      Header: '',
      tableManagerHeader: <BigHeaderCell>Content level</BigHeaderCell>,
      columns: [
        {
          Header: <BigHeaderCell>Content level</BigHeaderCell>,
          width: 200,
          accessor: 'contentLevel',
          className: 'border-right',
          enableColumnResize: true,
          sortType: withSortSkipped(sortTypes.jsxKey),
        },
      ],
    },
    hasLabels
      ? {
          accessor: 'labelHeader',
          disableSortBy: true,
          Header: '',
          tableManagerHeader: <BigHeaderCell>Label</BigHeaderCell>,
          columns: [
            {
              Header: <BigHeaderCell>Label</BigHeaderCell>,
              enableColumnResize: true,
              className: 'border-right',
              accessor: 'label',
              sortType: withSortSkipped(sortTypes.jsxKey),
            },
          ],
        }
      : null,
    {
      accessor: 'impactGroupHeader',
      disableSortBy: true,
      Header: '',
      tableManagerHeader: <BigHeaderCell>Impact group</BigHeaderCell>,
      columns: [
        {
          Header: <BigHeaderCell>Impact group</BigHeaderCell>,
          accessor: 'impactGroup',
          enableColumnResize: true,
          className: 'border-right',
          minWidth: 250,
          sortType: withSortSkipped(sortTypes.jsxKey),
        },
      ],
    },
    hasBudgetLimits
      ? {
          accessor: 'budgetLimitHeader',
          disableSortBy: true,
          Header: '',
          tableManagerHeader: <BigHeaderCell>Budget limit</BigHeaderCell>,
          columns: [
            {
              Header: <BigHeaderCell>Budget limit</BigHeaderCell>,
              enableColumnResize: true,
              className: 'border-right',
              disableSortBy: true,
              accessor: 'budgetLimit',
              minWidth: 250,
            },
          ],
        }
      : null,
    hasBidStrategy
      ? {
          accessor: 'bidStrategyHeader',
          disableSortBy: true,
          Header: '',
          tableManagerHeader: <BigHeaderCell>Bidding strategy</BigHeaderCell>,
          columns: [
            {
              Header: <BigHeaderCell>Bidding strategy</BigHeaderCell>,
              enableColumnResize: true,
              accessor: 'bidStrategy',
              className: 'border-right',
              disableSortBy: true,
              minWidth: 250,
            },
          ],
        }
      : null,
    ...funnelSteps.map((funnelStep) => ({
      accessor: `funnelStep${funnelStep.funnelStepId}Header`,
      disableSortBy: true,
      Header: '',
      tableManagerHeader: <BigHeaderCell>{capitalize(funnelStep.title)}</BigHeaderCell>,
      columns: [
        {
          className: 'border-right',
          Header: <BigHeaderCell>{capitalize(funnelStep.title)}</BigHeaderCell>,
          accessor: `funnel_${funnelStep.funnelStepId}`,
          disableSortBy: true,
          enableColumnResize: true,
          minWidth: 250,
          sortType: withSortSkipped(sortTypes.jsxKeyAsNumber),
        },
      ],
    })),
  ].filter(Boolean);
